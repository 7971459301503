
	import itemBox from "@/components/item-box.vue";
	/*import mapping from "@/components/main-page/mapping.vue";
										import news from "@/components/main-page/news/news.vue";
										import aboutProject from "@/components/main-page/about-project.vue";
										import disadvRoutes from "@/components/main-page/disadv-routes/disadv-routes.vue";
										import contact from "@/components/main-page/contact.vue";
										import footerBar from "@/components/main-page/footer-bar.vue";*/
	export default {
		name: "main-page",
		components: {
			itemBox,
		},
		async asyncData({ $axios }) {
			const products = await $axios
				.$get("/pomucky/search?sort=newest")
			return { products };
		},
		/*components: {
												mapping,
												news,
												aboutProject,
												disadvRoutes,
												contact,
												footerBar,
											}*/
	};
