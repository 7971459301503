import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0a60cb25&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0a60cb25&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a60cb25",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ItemBox: require('/opt/buildhome/repo/components/item-box.vue').default})
