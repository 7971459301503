
	export default {
		name: "item-box",
		props: ["product"],
		methods: {
			click() {
				this.$router.push(`/${this.product._id}`);
			},
		},
		data() {
			return {
				image: null,
				imageloaded: false,
			};
		},
		mounted() {
			console.log(this.product);
			this.$axios.get(`/pomucky/${this.product._id}/images`).then((res) => {
				if (res.status == 200) {
					if (res.data.length > 0) {
						console.log(res.data[0]._id);
						this.image = `${this.$axios.defaults.baseURL}/pomucky/${this.product._id}/images/${res.data[0]._id}/data`;
						console.log(this.image);
						//axios baseurl
					}
				}
			});
		},
	};
